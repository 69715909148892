import React from "react"

const SearchForm = () => {
  return (
    <div className="widget">
      <h6 className="title">Search Blog</h6>
      <hr />
      <form>
        <input className="mb0" type="text" placeholder="Type Here" />
      </form>
    </div>
  )
}

export default SearchForm
